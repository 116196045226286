import React, { useState } from "react";
import { auth } from '../../firebase'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import FacebookIcon from "assets/img/card-images/facebook-icon";
import GoogleIcon from "assets/img/card-images/google-icon";
import LinkedinIcon from "assets/img/card-images/linkedin-icon";
import { EyeIcon } from "@heroicons/react/solid";
import { CreateUser } from "api/CreateUser.js";
import { CheckActivationKey } from "api/CheckActivationKey.js";

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import vipLogo from "assets/files/images/vip-logo-mock.png"

export const SignUp = (props) => {
  const navigate = useNavigate()
  const params = useParams()
  const [showPass, setShowPass] = useState(false)
  const [userType, setUserType] = useState("verifier")
  // const [allowedKey, setAllowedKey] = useState()
  const [allowedKey, setAllowedKey] = useState(true)
  const [init, setInit] = useState(false)
  const [valid, setValid] = useState(false)
  const [errors, setErrors] = useState([])

  const validateRegex = {
    email: /^.+@.+\..+$/,
    empty: /^(|\040|\t)+$/,
    name: /^[A-Za-z0-9\-éïë\s]+$/,
    password: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z?!.,`@#$%^&*~_+\-:;='|\\[\]<>/{}€]{8,}/,
    phone: /^((\+))[0-9]{8,}$/gm
  }

  const validate = (field, data) => {
    if(field === 'email' && (validateRegex.email.test(data) && !validateRegex.empty.test(data))) return false
    if(field === 'pass' && (validateRegex.password.test(data) && !validateRegex.empty.test(data))) return false
    if(field === 'phone' && (validateRegex.phone.test(data) && !validateRegex.empty.test(data))) return false
    if(field === 'firstName' && (validateRegex.name.test(data) && !validateRegex.empty.test(data))) return false
    if(field === 'lastName' && (validateRegex.name.test(data) && !validateRegex.empty.test(data))) return false
    if(field === 'country' && (validateRegex.name.test(data) && !validateRegex.empty.test(data))) return false
    if(field === 'city' && (validateRegex.name.test(data) && !validateRegex.empty.test(data))) return false
    // validate dob to be in the past, after 1960 and not empty
    if(field === 'dob' && (data !== "" && new Date(data) < new Date() && new Date(data) > new Date(1960, 1, 1))) return false
    if(field === 'gender') return false 
    return true
  }

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    country:"",
    city:"",
    dob: "",
    gender: "",
    phone:"",
    email: "",
    pass:"",
    type: "verifier",
  })
  const [page, setPage] = useState(1)

    //Enable for activation flow
    // useEffect(()=>{
    //   if(!init){
    //     setInit(!init)
    //     CheckActivationKey({activationKey: params.id})
    //       .then((out) => {
    //         setAllowedKey({...out})
    //       })
    //   }
    // })

  const handleChange = (input, handle) => {
    if(validate(handle, input)) setErrors(current => [...new Set([...current, handle])])
    else setErrors([...errors.filter(e => e !== handle)])

    console.log('errors:', errors)  // @RENE hier staan de errors

    let obj = user
    obj[handle] = input
    setUser({...obj})
  }

  const showError = handle => {
    if(errors.includes(handle)){
      if(handle == "pass") return (<><div className="text-red-300 w-fit text-xs">Password must contain at least 8 chacters with at least one uppercase letter and one number.</div></>)
      // if(handle == "phone") return (<><div className="text-red-500 w-fit">Phone numbers must start with a country code (e.g. +31).</div></>)

      else if(handle == "phone"){
        if(user.phone.length > 8) return 
        else return(<><div className="text-red-300 w-fit text-xs">Phone numbers must start with a country code (e.g. +31).</div></>)
      }
      else if(handle == "dob") return (<><a className="text-red-300">Date of birth incorrect</a></>)
      else return(<><a className="text-red-300 text-xs"><span className="capitalize">{handle}</span> not correct</a></>)
    } 
  }

  const registerUser = (user) => {
    console.log('Registering User', user)
  }


  const handleLogin = (email, password , userType) => {
      signInWithEmailAndPassword(auth, email, password)
      .then(userCredentials => {
        const user = userCredentials.user;
        const accessToken = user.accessToken;
        localStorage.setItem('token', accessToken);
        localStorage.setItem('user', userType);
        navigate('/home')
      })
      .catch(error => alert(error.message))
  }


  const handleSignUp = () => {
      createUserWithEmailAndPassword(auth, user.email, user.pass)
      .then(userCredentials => {
        const newUser = userCredentials.user;
        // After this is successful make the API call to add user in the DB
        CreateUser({
            user: {
              firstName: user.firstName,
              lastName: user.lastName,
              country: user.country,
              city: user.city,
              dob: user.dob,
              gender: user.gender,
              phone: user.phone,
              email: user.email,
              type: userType
            },
          // activationKey: params.id
        }, async (err, out) => {
           if(!err) {
             handleLogin(user.email, user.pass, 'unapprovedVerifier')
           }
          })
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getDisabled = () => {
    if(userType === "verifier"){
      if(
          validate('firstName', user.firstName) ||
          validate('lastName', user.lastName) ||
          validate('country', user.country) || 
          validate('dob', user.dob) || 
          // validate('phone', user.phone) ||
          validate('email', user.email) ||
          validate('pass', user.pass)
        ) return true
        else return false
      }
    else if(userType === "marketplace"){
      if(
        user.fullName == "" ||
        user.email == ""
      ) return true
      else return false
    }
  }


  return(
    <div className="h-screen w-screen">
      <div className="w-fit m-auto pt-24">
        <img
          className="scale-[80%]"
          src={vipLogo}
        />
      </div>
    {allowedKey && <div className="m-0 ">
      <div className="text-2xl font-semibold py-4   w-fit m-auto">
        Create your account as a {userType}
      </div>
        <div className="max-w-2xl m-auto text-center ">

        <div className="flex center-items space-x-12">
          {/* <div className={`${userType=="marketplace" ? "button-confirm hover:bg-vipGreen hover:text-white" : "button-cancel"} w-fit my-8`} onClick={()=>{setUserType("marketplace")}}>
            Marketplace
          </div> */}
          {/* <div className={`${userType=="verifier" ? "button-confirm hover:bg-vipGreen hover:text-white" : "button-cancel"} w-fit my-8`} onClick={()=>setUserType("verifier")}>
            Verifier
          </div> */}
        </div>

      {/*<div className="py-4">
        Create account using social networks
      </div>
      <div className="flex text-center w-fit space-x-4 m-auto">
        <div className="">
          <FacebookIcon className="hover:scale-105 animate cursor-pointer" width={30}/>
        </div>
        <div>
          <GoogleIcon className="hover:scale-105 animate cursor-pointer" width={30} />
        </div>
        <div>
          <LinkedinIcon className="hover:scale-105 animate cursor-pointer" width={30} />
        </div>
      </div>*/}
        {/*<div className="flex items-center w-fit m-auto py-4">
          <div className="mx-2 border-b-2 border-vipGreen w-[100px] pt-[2px]"/>
          <div className="font-bold px-3">
            Or
          </div>
          <div className="mx-2 border-b-2 border-vipGreen w-[100px] pt-[2px]"/>
        </div>*/}
      {userType === "marketplace" &&
        <>
          <div className="w-fit m-auto text-left py-1">
            <div className="py-1 text-sm">
              Your name
            </div>
            <div className="">
              <input
                onChange={(e)=>handleChange(e.target.value, "fullName")}
                placeholder="Full name"
                type="text"
                className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
              />
            </div>
          </div>
          <div className="w-fit m-auto text-left py-1">
            <div className="py-1 text-sm">
              E-mail
            </div>
            <div className="">
              <input
                onChange={(e)=>handleChange(e.target.value, "email")}
                placeholder="Email address"
                type="email"
                className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
              />
            </div>
          </div>
        </>
      }
  {  /*  <div className="w-fit m-auto text-left py-1">
        <div className="py-1 text-sm">
          Password
        </div>
        <div className="relative">
          <div className="text-gray-300 hover:text-gray-600 animate absolute z-10 top-[5px] right-2 cursor-pointer select-none"
            onClick={(e)=> setShowPass(!showPass) }
          >
            <EyeIcon width={20}/>
          </div>
          <input
            onChange={(e)=>handleChange(e.target.value, "pass")}
            placeholder="Password"
            type={`${showPass ? "text" : "password"}`}
            className="relative md:w-[240px] bg-white pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
          />
        </div>
      </div>*/}
      {/* <button className="button-confirm w-fit m-auto my-8 disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-400 disabled:hover:shadow-none"
        disabled={(userType === "")}
        onClick={()=>{
          if(userType=="verifier")setPage(1)
          else if (userType == "marketplace") console.log('market place selected')
        }}
        >
        Continue
      </button> */}
      </div>
      {userType === "verifier" &&
      <>
        <div className="max-w-2xl m-auto text-center flex">
          <div className="w-1/2">
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Name*
              </div>
              <div className="">
                <input
                  onChange={(e)=>handleChange(e.target.value, "firstName")}
                  placeholder="First name"
                  type="text"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
              <div>
                { showError('firstName') }
              </div>
            </div>
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Surname*
              </div>
              <div className="">
                <input
                  onChange={(e)=>handleChange(e.target.value, "lastName")}
                  placeholder="Surname"
                  type="text"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
              <div>
                { showError('lastName') }
              </div>
            </div>
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Country*
              </div>
              <div className="">
                <input
                  onChange={(e)=>handleChange(e.target.value, "country")}
                  placeholder="Country"
                  type="text"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
            </div>

            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                E-mail*
              </div>
              <div className="">
                <input
                  onChange={(e)=>handleChange(e.target.value, "email")}
                  placeholder="Email address"
                  type="email"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
              <div>
                { showError('email') }
              </div>
            </div>
            <div className="w-fit m-auto text-left py-1 max-w-[240px]">
              <div className="py-1 text-sm">
                Password*
              </div>
              <div className="relative">
                <div className="text-gray-300 hover:text-gray-600 animate absolute z-10 top-[5px] right-2 cursor-pointer select-none"
                  onClick={(e)=> setShowPass(!showPass) }
                >
                  <EyeIcon width={20}/>
                </div>
                <input
                  onChange={(e)=>handleChange(e.target.value, "pass")}
                  placeholder="Password"
                  type={`${showPass ? "text" : "password"}`}
                  className="relative md:w-[240px] bg-white pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
              <div className="w-full"> 
                { showError('pass') }
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Date of birth*
              </div>
              <div className="">
                <input
                  onChange={(e)=>handleChange(e.target.value, "dob")}
                  placeholder="Email address"
                  type="date"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
                { showError('dob') }
            </div>
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Gender
              </div>
              <div className="flex">
                <div className="mx-2">
                  <input
                    onChange={(e)=>handleChange("male", "gender")}
                    name="radio"
                    type="radio"
                    className="mr-2 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                  />
                  Male
                </div>
                <div className="mx-2">
                  <input
                    onChange={(e)=>handleChange("female", "gender")}
                    name="radio"
                    type="radio"
                    className="mr-2 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                  />
                  Female
                </div>
                <div className="mx-2">
                  <input
                    onChange={(e)=>handleChange("other", "gender")}
                    name="radio"
                    type="radio"
                    className="mr-2 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                  />
                  Other
                </div>
              </div>
            </div>
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                City
              </div>
              <div className="">
                <input
                  onChange={(e)=>handleChange(e.target.value, "city")}
                  placeholder="City"
                  type="text"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
              <div>
                {/* { showError('city') } */}
              </div>
            </div>
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Phone Number*
              </div>
              <div className="">
                <input
                  onChange={(e)=>handleChange(e.target.value, "phone")}
                  placeholder="Phone Number"
                  type="text"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
                <p className="text-xs text-gray-500">Format: +1XXXXXXXXXX</p>
              </div>
              <div className="max-w-[240px]"> 
                { showError('phone') }
              </div>
            </div>
          </div> 
        </div>
        <div className="max-w-xl text-sm text-slate-500 m-auto pt-2">
          * mandatory fields
        </div>
        <div className="max-w-xl text-sm text-gray-500 m-auto pt-2">
          In the next step, you will take a verifier test to determine your direction and your strengths as a verifier. You have only one attempt to pass this test. You will have 100 questions. Please prepare and take the test.
          In the future, you will have the opportunity to take some sections of this test if you want to expand your specialization and take on more orders.
          Good luck!
        </div>
      </>
      }
        <div className="flex w-fit m-auto space-x-8">
          <div className="button-cancel w-fit my-8" onClick={()=>navigate("/login")}>
            Go back
          </div>
          <button 
            className="button-confirm w-fit my-8 disabled:bg-gray-300 disabled:border-gray-400 disabled:text-gray-400 disabled:hover:shadow-none" 
            disabled={getDisabled()} 
            onClick={()=>{
              console.log('User', user)
              props.notify("✓ Account created succesfully.")
              setPage(1)
              handleSignUp()
            }}
          >
            Complete Account
          </button>
        </div>
      </div>}
      {
        !allowedKey && <div> Incorrect Activation Code </div>
      }
    </div>

  )
}
